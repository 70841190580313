import { ReadMoreWeb } from 'react-shorten';
import React from 'react';

const TextShorten: React.FC<{
  truncate?: number;
  children: React.ReactNode;
}> = ({ truncate, children }) => (
  <ReadMoreWeb
    truncate={truncate}
    showMoreText="Show more"
    showLessText="Show less"
    className="read-more-btn orangeText"
  >
    {children}
  </ReadMoreWeb>
);

export default TextShorten;

import React from 'react';
import Image from 'next/image';
import './styles.scss';

const TimeTested: React.FC = () => {
  return (
    <section className="count-content text-level-mobile-view">
      <div className="section-container">
        <h1 className="text-white text-center">
          <span className="orangeText block capitalize">
            A time-tested method
          </span>
          For Authentic, Fun & Easy Learning
        </h1>
        <div className="max-w-65xl timetestedWrap flex justify-center items-center pb-10 mt-10">
          <div className="timetestedCircle timetestedCircle-1 flex justify-center items-center flex-col">
            <h2 className="text-white">Dikhya</h2>
            <Image
              src="/timetested-head-divider.png"
              alt="Dikhya"
              title="Dikhya"
              width={75}
              height={21}
              className="mx-auto -mt-3"
            />
            <p className="leading-10 mt-5 font-normal">Learn From Master</p>
            <p className="mt-5 font-normal">Know What&#39;s Best!</p>
          </div>

          <div className="timetestedCircle timetestedCircle-2 flex justify-center items-center flex-col -mx-6 z-10">
            <h2 className="text-white">Sikhya</h2>
            <Image
              src="/timetested-head-divider.png"
              alt="Sikhya"
              title="Sikhya"
              width={75}
              height={21}
              className="mx-auto -mt-3"
            />
            <p className="leading-10 mt-5 font-normal">
              Personal Attention & Feedback in LIVE classes +
            </p>
            <p className="mt-5 font-normal">
              Get Riyaz (Practice) routines for self learning
            </p>
          </div>

          <div className="timetestedCircle timetestedCircle-1 timetestedCircle-3 flex justify-center items-center flex-col">
            <h2 className="text-white">Parikhya</h2>
            <Image
              src="/timetested-head-divider.png"
              alt="Parikhya"
              title="Parikhya"
              width={75}
              height={21}
              className="mx-auto -mt-3"
            />
            <p className="leading-10 mt-5 font-normal">
              Apply Your Learning To Short Performance
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(TimeTested);

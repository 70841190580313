import React from 'react';
import Image from 'next/image';
import { WhatsAppUrl, WhataAppMessage } from '@/constants';
import '../../common/InviteMembers/style.scss';

interface WhatsAppSectionProps {
  btnClass?: string;
}

const InviteMembers: React.FC<WhatsAppSectionProps> = ({ btnClass }) => {
  const sendMessage = () => {
    let url = `${WhatsAppUrl}`;

    if (WhataAppMessage) {
      url += `?text=${encodeURI(WhataAppMessage)}`;
    }

    window.open(url, '_blank');
  };

  return (
    <section className="bg-white section-spacing relative whatsappSections invite-f">
      <div className="section-container">
        <div
          className={`whatsappSections-wrapper ${location.pathname === '/course-navigation' ? '!hidden' : ''}`}
        >
          <div className="whtsapp-right-content">
            <div>
              <Image
                src="/invite-clip.svg"
                alt="kathak-adults"
                title="kathak-adults"
                width={477}
                height={333}
                loading="lazy"
              />
            </div>
          </div>
          <div className="whtsapp-left-content">
            <h1>Invite your Family & Friends to Learn Indian Arts</h1>
            <div>
              <Image
                src="/shape-r.svg"
                alt="kathak-adults"
                title="kathak-adults"
                width={355}
                height={24}
                loading="lazy"
              />
            </div>
            <p>
              Easy to follow, Fun and Authentic Learning to be Creative, Joyful
              and Healthy
            </p>
          </div>
        </div>
        <div className="whtsapp-btn-content">
          <button
            onClick={sendMessage}
            className={`px-5 py-4 font-normal whatsappBtn text-white ${btnClass}`}
          >
            <p>Invite via WhatsApp</p>
            <Image
              src="/WhatsApp-icon.png"
              alt="WhatsApp"
              title="WhatsApp"
              width={85}
              height={41}
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default React.memo(InviteMembers);

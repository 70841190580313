import React from 'react';
import './style.scss';

type ButtonType = 'button' | 'submit' | 'reset';
interface ButtonProps {
  onClick?: () => void;
  title: string;
  subtitle?: string;
  classeName?: string;
  type?: ButtonType;
  showIcon?: boolean;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  title,
  subtitle,
  classeName,
  type = 'button',
  showIcon = true,
  disabled = false
}) => {
  return (
    <div className="btn-align-view">
      <button
        onClick={onClick}
        type={type}
        className={`primeryButton mb-0 flex margin-align-mobile-view px-7 py-3 font-medium items-center rounded-xl ${classeName}`}
        disabled={disabled}
      >
        <p className="mr-3 sm:mr-4 price-btn-adjested">
          {title}
          {subtitle && (
            <>
              <br />
              <span className="font-light">{subtitle}</span>
            </>
          )}
        </p>
        {showIcon ? <span aria-hidden="true"></span> : null}
      </button>
    </div>
  );
};

export default React.memo(Button);

import React from 'react';
import Image from 'next/image';
import { WhatsAppNo, WhatsAppUrl } from '@/constants';
import './styles.scss';

interface WhatsAppSectionProps {
  label?: string;
  btnClass?: string;
}

const WhatsAppSection: React.FC<WhatsAppSectionProps> = ({ btnClass }) => {
  const sendMessage = () => {
    const url = `${WhatsAppUrl}/${WhatsAppNo}`;

    window.open(url, '_blank');
  };

  return (
    <section className="contactSection">
      <div className="section-container">
        <div className="chat-bottom-text">
          <h1>Chat with us</h1>
          <button
            onClick={sendMessage}
            className={`flex justify-center items-center px-5 py-2 font-normal whatsappBtn text-white ${btnClass}`}
          >
            <Image
              src="/WhatsApp-icon.png"
              alt="WhatsApp"
              title="WhatsApp"
              width={85}
              height={41}
            />
            <p>+91 {WhatsAppNo}</p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default React.memo(WhatsAppSection);

import React from 'react';
import './styles.scss';

const Rating: React.FC = () => {
  return (
    <section className="bg-white number-count-content">
      <div className="section-container">
        <div className="grid grid-cols-3 learn-text ">
          <div className="text-center py-3 px-2 my-2">
            <div className="text-base text-white font-bold">
              <h2 className="text-6xl orangeText 44base">50,000+</h2>
              <h3 className="text-3xl text-black font-bold">Learners</h3>
            </div>
          </div>

          <div className="text-center py-3 my-2 px-2 orangeBorder border-r-2 border-l-2">
            <div className="text-base text-white font-bold">
              <h2 className="text-6xl orangeText">4.8/5</h2>
              <h3 className="text-3xl text-black font-bold">Course Rating</h3>
            </div>
          </div>
          <div className="text-center py-3 my-2 px-2">
            <div className="text-base text-white font-bold">
              <h2 className="text-6xl orangeText">7,20,000+</h2>
              <h3 className="text-3xl text-black font-bold">
                minutes of Learning
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Rating);
